import { Box, Container, Typography } from "@mui/material"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import { useContext, useLayoutEffect, useMemo, useState } from "react";
import Accordion from "../Accordion/Accordion";
import LinearProgressWithLabel from "../LinearProgressWithLabel/LinearProgressWithLabel";
import { useParams } from "react-router-dom";
import { store } from "../../store/store";
import { observer } from "mobx-react-lite";
import { trackPromise } from "react-promise-tracker";
import { BASE_URL } from "../../constants";
import { colors } from "../../constants/colors";
import { Context } from "../../App";
import { IDocumentList } from "../../types";

const DocsRole: React.FC = () => {
  const { roleId } = useParams();
  const {  isTablet } = useContext(Context)

  const { document, user } = store;
  const [data, setData] = useState<IDocumentList[]>([]);
  const [roleDetail, setRoleDetail] = useState({ id: "", name: "", pictureUrl: "" })

  const percent = useMemo(() => {
    const total = data.reduce((acc, cur) => acc + (cur?.documents ?? []).filter(Boolean).length, 0)
    const reading = data.reduce((acc, cur) => acc + (cur?.documents ?? []).filter(item => item.read).length, 0)
    return Math.round(reading / total * 100)
  }, [data])

  useLayoutEffect(() => {
    if (roleId) {
      trackPromise(
        document.getList({ roleId })
          .then(() => setData(document.documentList))
          .then(() => user.getRoleDetail(roleId)
            .then((data) => setRoleDetail(data)))
      )
    }
  }, [])
  const links = [
    { title: "Главная", href: "/" },
    { title: roleDetail.name ?? "", href: "/" },
  ]

  const onRead = (id: string) => {
    if (roleId) {
      document.checkToRead(id).then(() => {
        document.getList({ roleId })
          .then(() => setData(document.documentList))
      })
    }
  }
  return (<>
    {/* вынести в отдельный компонент */}
    <Container
      sx={{
        padding: isTablet ? "0 !important" : "",
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "50rem",
          backgroundImage: `url("${BASE_URL.BASE}${roleDetail.pictureUrl}")`,
          backgroundColor: colors.black50,
          backgroundBlendMode: "multiply",
          borderRadius: isTablet ? "0 0 2.4rem 2.4rem" : "2.4rem",
          justifyContent: "space-between",
          padding: isTablet ? "2rem clamp(2rem, 5vw, 3.2rem)" : 3.2,
          paddingTop: 0,
        }
        }
      >
        <Breadcrumbs links={links} light={true} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h1"
            color={colors.white}
            paddingBottom={"3.2rem"}
            paddingTop={"7rem"}
          >
            Документы для профессии  «{roleDetail.name}»
          </Typography>
          <LinearProgressWithLabel value={percent} />
        </Box>
      </Box >
    </Container>
    <Container>

      <Box
        display={"grid"}
        flexDirection={"column"}
        gap={"1.2rem"}
        marginTop={isTablet ? 4 : 6}
        sx={{
          gridTemplateColumns: isTablet ? "1fr" : "1fr 1fr"
        }}
      >
        {
          document.documentList && document.documentList?.map((item, index) => (
            <Accordion
              key={index}
              title={item.sectionName}
              details={item.documents ?? []}
              onRead={onRead}
            />
          ))
        }
      </Box>
    </Container>
  </>

  )
}
export default observer(DocsRole)